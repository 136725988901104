import React from 'react'
import Img from 'gatsby-image'
import Marquee from 'react-fast-marquee'

import { webpImages, IWebpImages } from '../../assets/images/_webp-images'

import usePageQuery from '../../pageQuery'

import dataJson from './data.json'

import { ImgWebp } from 'src/styles/imgWebp'
import * as S from './style'

interface ICard {
  photo: string;
  name: string;
  comment: string;
  insuranceName: string;
}

const TaNaBocaDoPovo = () => {
  const CardData: ICard[] = dataJson
  const data = usePageQuery()
  const {
    bgTaNaBocaDoPovo,
  }: IWebpImages = webpImages

  return (
    <S.TaNaBocaDoPovoSection className=''>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 mb-4 mb-md-0'>
            <ImgWebp src={bgTaNaBocaDoPovo.xl} breakPointsSize={{ sm: '100%', md: '304px', lg: '392px', xl: '622px' }} role='img' aria-label='casal abraçado e sorrindo olhando para celular' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1 offset-xl-1 d-md-flex flex-column justify-content-md-center'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 font-citrina fw-500 text-grayscale--500 mb-4'>Tá na boca do povo!</h2>
            <p className='fs-18 lh-22 text-grayscale--400'>O Inter tem um seguro com o perfil do seu negócio. Empresa saudável é empresa protegida.</p>
          </div>
          <div className='col-12 mt-md-5'>
            <div className='wrw'>
              <Marquee pauseOnHover={true} pauseOnClick={true} speed={90}>
                {CardData.map((card: ICard) => (
                  <div key={card.photo} className='px-2'>
                    <S.Card className='d-flex flex-column justify-content-between p-3'>
                      <div>
                        <div className='d-flex align-items-center'>
                          <S.FotoPerfil>
                            <Img fluid={data[card.photo].fluid} alt='Foto de perfil de Anderson Galvão' />
                          </S.FotoPerfil>
                          <div className='d-flex flex-column ml-3'>
                            <span className='fs-12 lh-14 fw-700 text-grayscale--500'>{card.name}</span>
                          </div>
                        </div>
                        <div className='mt-3'>
                          <p className='fs-14 lh-16 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.comment }} />
                        </div>
                      </div>
                      <div>
                        <S.TipoSeguro className='fs-10 lh-12 fw-700 text-grayscale--500'>
                          {card.insuranceName}
                        </S.TipoSeguro>
                      </div>
                    </S.Card>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </S.TaNaBocaDoPovoSection>
  )
}

export default TaNaBocaDoPovo
