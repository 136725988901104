import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

interface IPrimaryCTASeguros {
  textColor: string;
  backgroundColor: string;
}

export const Wrapper = styled.div`

`

export const PrimaryCTASeguros = styled.a<IPrimaryCTASeguros>`
  color: ${(props: IPrimaryCTASeguros) => props.textColor};
  background-color: ${(props: IPrimaryCTASeguros) => props.backgroundColor};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 48px;

  &:hover {
    color: ${(props: IPrimaryCTASeguros) => props.textColor};
    transition: 0.3s;
    opacity: 0.9;
  }

  @media ${device.tablet} {
    width: 332px;
  }

  @media ${device.desktopLG} {
    width: 376px;
  }

  @media ${device.desktopXL} {
    width: 456px;
  }
`
