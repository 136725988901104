import React from 'react'
import slugify from 'slugify'

import { webpImages, IWebpImages } from '../../assets/images/_webp-images'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { ImgWebp } from 'src/styles/imgWebp'
import { PrimaryCTASeguros } from '../../style'
import { brand } from 'src/styles/colors'
import * as S from './style'

interface ICard {
  title: string;
  description: string;
}

const ComoFuncionamOsSegurosInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const {
    bgComoFuncoinamOsSegurosInter,
  }: IWebpImages = webpImages

  const cards: ICard[] = [
    {
      title: 'Tudo em um só lugar',
      description: 'Proteção pra sua família, bens e futuro na palma da mão.',
    },
    {
      title: 'Contratação 100% digital',
      description: 'Faça tudo pelo Super App, sem sair do sofá.',
    },
    {
      title: 'Rápido e descomplicado',
      description: 'Ative em poucos cliques, sem papeladas e burocracia.',
    },
  ]

  return (
    <S.ComoFuncionamOsSegurosInterSection className=''>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-xl-1 order-md-2 mb-5 mb-md-0 text-xl-right'>
            <ImgWebp src={bgComoFuncoinamOsSegurosInter.xl} breakPointsSize={{ sm: '100%', md: '328px', lg: '408px', xl: '516px' }} role='img' aria-label='mulher de roupa laranja com celular na mão e sorrindo' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-md-1 mb-5 mb-md-0 d-md-flex align-items-md-center'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-56 lh-xl-62 font-citrina fw-500 text-grayscale--500 mb-0'>Como funcionam os Seguros Inter?</h2>
          </div>
          <div className='col-12 order-md-3 mb-4 pb-2 mt-md-5 cards-container'>
            {cards.map((card: ICard) => (
              <S.Cards key={slugify(card.title)} className='d-flex align-items-center justify-content-center'>
                <div className=''>
                  <h3 className='fs-20 lh-25 fs-lg-24 lh-lg-30 font-sora fw-400 text-grayscale--500 mb-3'>{card.title}</h3>
                  <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 font-inter text-grayscale--400'>{card.description}</p>
                </div>
              </S.Cards>
            ))}
          </div>
          <div className='col-12 order-md-4 d-md-flex justify-content-md-center'>
            <PrimaryCTASeguros
              onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_5',
                      element_name: 'Abrir conta gratuita',
                      section_name: 'Como funcionam os Seguros Inter?',
                      redirect_url: 'https://intergo.app/19a9e7c2',
                      element_action: 'click button',
                    })
              }} href='https://intergo.app/19a9e7c2' backgroundColor={brand.primary} textColor='white'
            >Abrir conta gratuita
            </PrimaryCTASeguros>
          </div>
        </div>
      </div>
    </S.ComoFuncionamOsSegurosInterSection>
  )
}

export default ComoFuncionamOsSegurosInter
