import React from 'react'
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'

const FAQSect = () => {
  return (
    <section id='perguntas-frequentes' className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row'>
          <div className='mb-4'>
            <h3 className='fs-20 fs-md-32 fs-xl-40 lh-25 lh-md-40 lh-xl-50 fw-600 text-grayscale--500'>Perguntas frequentes</h3>
          </div>
          <FAQ id='cashbacks' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='summary-content px-0' answerData={pageContext.structuredData.faq} searchBg='#ffffff' />
        </div>
      </div>
    </section>
  )
}

export default FAQSect
