import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { Wrapper } from './style'
import {
  Hero,
  DiversasSolucoes,
  CuidarDaSaude,
  SolucoesProSeuNegocio,
  ComoFuncionamOsSegurosInter,
  TaNaBocaDoPovo,
  FAQSect,
} from './_sections/_index'

const Seguros = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <DiversasSolucoes />
        <CuidarDaSaude />
        <SolucoesProSeuNegocio />
        <ComoFuncionamOsSegurosInter />
        <TaNaBocaDoPovo />
        <FAQSect />
      </Layout>
    </Wrapper>
  )
}

export default Seguros
