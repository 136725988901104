import React from 'react'

import { Link } from 'gatsby'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { webpImages, IWebpImages } from '../../assets/images/_webp-images'

import { PrimaryCTASeguros } from '../../style'
import { ImgWebp } from 'src/styles/imgWebp'
import { laranja } from 'src/styles/colors'
import * as S from './style'

const SolucoesProSeuNegocio = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const {
    bgSolucoesProSeuNegocio,
  }: IWebpImages = webpImages

  return (
    <S.SolucoesProSeuNegocioSection className='d-md-flex align-items-md-center' role='img' aria-label='mulher mexendo em celular'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 d-md-none mb-4'>
            <ImgWebp src={bgSolucoesProSeuNegocio.sm} breakPointsSize={{ sm: '100%' }} />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-7 offset-md-6'>
            <h2 className='fs-28 lh-34 fs-xl-48 lh-xl-52 font-citrina fw-500 text-white mb-4'>Soluções pro seu negócio no Super App PJ</h2>
            <p className='fs-18 lh-22 text-white'>O Inter tem um seguro com o perfil do seu negócio. Empresa saudável é empresa protegida.</p>
            <PrimaryCTASeguros
              onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      element_name: 'Saber Mais',
                      section_name: 'Soluções pro seu negócio no Super App PJ',
                      redirect_url: '/empresas/conta-digital/pessoa-juridica/',
                      element_action: 'click button',
                    })
              }} as={Link} to='/empresas/conta-digital/pessoa-juridica/' textColor={laranja.terra} backgroundColor='white'
            >Saber mais
            </PrimaryCTASeguros>
          </div>
        </div>
      </div>
    </S.SolucoesProSeuNegocioSection>
  )
}

export default SolucoesProSeuNegocio
