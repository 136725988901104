import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'
import styled from 'styled-components'

export const ComoFuncionamOsSegurosInterSection = styled.section`
  background-color: ${gray[400]};

  padding: 24px 0;

  @media ${device.tablet} {
    padding: 46px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media ${device.tablet} {
      flex-direction: row;
    }

    @media ${device.desktopXL} {
      justify-content: center;
    }
  }
`

export const Cards = styled.div`
  background-color: white;
  border-radius: 16px;

  padding: 24px;

  width: 100%;
  height: 172px;

  @media ${device.tablet} {
    width: 216px;
    height: 238px;
  }

  @media ${device.desktopLG} {
    width: 294px;
    height: 238px;
  }

  @media ${device.desktopXL} {
    width: 358px;
    height: 216px;
  }
`
