import styled from 'styled-components'
import { brand, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const CuidarDaSaudeSection = styled.section`
  background-color: ${brand.sand};

  padding: 24px 0;

  @media ${device.tablet} {
    padding: 46px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }
`

export const Card = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  height: 216px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    height: 224px;
  }

  @media ${device.desktopLG} {
    height: 248px;
  }

  @media ${device.desktopXL} {
    height: 272px;
  }
`
