import { device } from 'src/styles/breakpoints'
import { brand } from 'src/styles/colors'
import styled from 'styled-components'
import { webpImages, IWebpImages } from '../../assets/images/_webp-images'

const { backgroundHero }: IWebpImages = webpImages

export const HeroSegurosSection = styled.section`
  background-color: ${brand.primary};
  padding: 32px 0;

  @media ${device.tablet} {
    padding: 0;
    background-image: url(${backgroundHero.md});
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    height: 310px;
  }

  @media ${device.desktopLG} {
    background-image: url(${backgroundHero.lg});
    height: 390px;
  }

  @media ${device.desktopXL} {
    background-image: url(${backgroundHero.xl});
    height: 590px;
  }
`
