import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      oQueEstaoFalandoBanner: imageSharp(fluid: {originalName: { regex: "/banner-o-que-estao-falando/" }}) {
        fluid(maxWidth: 594, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      oQueEstaoFalandoBackgroundLG: imageSharp(fluid: {originalName: { regex: "/background-o-que-estao-falando-lg/" }}) {
        fluid(maxWidth: 405, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      oQueEstaoFalandoBackgroundXL: imageSharp(fluid: {originalName: { regex: "/background-o-que-estao-falando-xl/" }}) {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoAdelsonMiranda: imageSharp(fluid: {originalName: { regex: "/photo-adelson-miranda/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoAndersonGalvao: imageSharp(fluid: {originalName: { regex: "/photo-anderson-galvao/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoEskulaxo: imageSharp(fluid: {originalName: { regex: "/photo-eskulaxo/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoIgorFilo: imageSharp(fluid: {originalName: { regex: "/photo-igor-filo/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoJessicaLopes: imageSharp(fluid: {originalName: { regex: "/photo-jessica-lopes/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoJessicalopest: imageSharp(fluid: {originalName: { regex: "/photo-jessicalopest/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoKaioSilva: imageSharp(fluid: {originalName: { regex: "/photo-kaio-silva/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoNayaraLima: imageSharp(fluid: {originalName: { regex: "/photo-nayara-lima/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoOliveira: imageSharp(fluid: {originalName: { regex: "/photo-oliveira/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoZedFrancisco: imageSharp(fluid: {originalName: { regex: "/photo-zed-franscisco/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cardImageSeguroCelular: imageSharp(fluid: {originalName: { regex: "/card-image-seguro-celular/" }}) {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cardImageCartaoMaisProtegido: imageSharp(fluid: {originalName: { regex: "/card-image-cartao-mais-protegido/" }}) {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerConhecaTodasAsNossasSolucoes: imageSharp(fluid: {originalName: { regex: "/banner-conheca-todas-as-nossas-solucoes/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageCardBlogComoFuncionaOSeguroDeVida: imageSharp(fluid: {originalName: { regex: "/image-card-blog-como-funciona-o-seguro-de-vida/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageCardBlogDicionarioDeSeguros: imageSharp(fluid: {originalName: { regex: "/image-card-blog-dicionario-de-seguros/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imageCardBlogPlanoDeSaude: imageSharp(fluid: {originalName: { regex: "/image-card-blog-plano-de-saude/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
