import { device } from 'src/styles/breakpoints'
import { laranja } from 'src/styles/colors'
import styled from 'styled-components'
import { webpImages, IWebpImages } from '../../assets/images/_webp-images'

const { bgSolucoesProSeuNegocio }: IWebpImages = webpImages

export const SolucoesProSeuNegocioSection = styled.section`
  background-color: ${laranja.terra};

  padding: 32px 0;

@media ${device.tablet} {
  padding: 0;
  background-image: url(${bgSolucoesProSeuNegocio.md});
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 326px;
}

@media ${device.desktopLG} {
  background-image: url(${bgSolucoesProSeuNegocio.lg});
}

@media ${device.desktopXL} {
  background-image: url(${bgSolucoesProSeuNegocio.xl});
  height: 480px;
}
`
