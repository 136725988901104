import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const TaNaBocaDoPovoSection = styled.section`
  padding: 24px 0;

  @media ${device.tablet} {
    padding: 46px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }

  .inside-container {
    padding: 0 24px;

    @media ${device.tablet} {
      padding: 0 38px;
    }
  }

  .overlay {
    :after {
      opacity: 0;
    }
    :before {
      opacity: 0;
      @media ${device.tablet} {
        opacity: 1;
      }
    }
  }

  .marquee {
    --duration: 78s !important;
    @media ${device.tablet} {
      --duration: 60s !important;   
    }
  }
`

export const Card = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  width: 248px;
  height: 270px;

  @media ${device.tablet} {
    width: 300px;
    height: 224px;
  }
  @media ${device.desktopLG} {
    width: 360px;
    height: 210px;
  }
`
export const FotoPerfil = styled.div`
  width: 24px;
  height: 24px;
`

export const TipoSeguro = styled.span`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 2px 4px;
`
