import React from 'react'

import { PrimaryCTASeguros } from '../../style'
import { brand, white } from 'src/styles/colors'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.HeroSegurosSection className='d-md-flex align-items-md-center' role='img' aria-label='homem mexendo no celular e sorrindo'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7'>
            <h1 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-56 lh-xl-62 font-citrina fw-500 text-white'>Seguros Inter: proteção pra tudo que importa</h1>
            <p className='fs-18 lh-22 fw-400 text-white'>No Inter é fácil fazer um seguro. Soluções 100% digitais na plataforma de proteção mais completa do Brasil.</p>
            <PrimaryCTASeguros
              onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      element_name: 'Conheça nossos produtos',
                      section_name: 'Seguros Inter: proteção pra tudo que importa',
                      redirect_url: 'https://intergo.app/19a9e7c2',
                      element_action: 'click button',
                    })
              }}
              href='https://intergo.app/19a9e7c2' textColor={brand.primary} backgroundColor={white}
            >Conheça nossos produtos
            </PrimaryCTASeguros>
          </div>
        </div>
      </div>
    </S.HeroSegurosSection>
  )
}

export default Hero
